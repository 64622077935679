<template>
	<div class="grid">
		<div class="md:col-8 col-12">
			<Card class="mb-4">
				<template #title>
					{{title}}
				</template>
				<template #content>
                    <Fieldset>
						<template #legend>
							<i class="pi pi-info-circle"></i>
						</template>
						<div v-html="kta_print_message"></div>
					</Fieldset>
                    <Divider />
					<Message v-if="kta_status == '0'" severity="error">
						{{kta_error_message}}
					</Message>

                    <Button label="Print" @click="printKTA" :disabled="printForm.busy" :icon="(printForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-print'" />
				</template>
			</Card>
		</div>

        <div id="print-area" class="hide"></div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			title: 'Print KTA',
			api: '/api/member-self',
            kta_print_message: null,
            kta_status: null,
            kta_error_message: null,
            printForm: new this.$FormAuth({
				// id: null,
			}),
		}
	},
	computed: {
		
	},
	methods: {
        loadData(){
            this.$Progress.start();
			this.$httpAuth.get(this.api)
			.then((response) => {
				this.$Progress.finish();
				this.kta_print_message = response.data.kta_print_message;
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
        },
		printKTA(){
            this.$Progress.start();
            this.printForm.post(this.api)
            .then((response) => {
                this.$Progress.finish();
                if(response.data.status == '1'){
                    setTimeout(() => {
                        $("#print-area").html(response.data.kta.content);

                        $("#print-area").html(function(index,html){
                            return html.replace(/{{nama}}/g, response.data.member.nama);
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{nta}}/g, response.data.member.nta);
                        });
                        $("#print-area :not(:has(*))").html((index,self) => {
                            return self.replace(/{{tgl_lahir}}/g, this.$filter_date(response.data.member.tgl_lahir));
                        });
                        $("#print-area :not(:has(*))").html((index,self) => {
                            return self.replace(/{{no_hp}}/g, response.data.member.no_hp);
                        });
                        $("#print-area :not(:has(*))").html((index,self) => {
                            return self.replace(/{{email}}/g, response.data.member.email);
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{nama_instansi}}/g, response.data.member.nama_instansi);
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{alamat}}/g, response.data.member.alamat);
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{provinsi}}/g, (response.data.member.province) ? response.data.member.province.name : '');
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{kabupaten}}/g, (response.data.member.regency) ? response.data.member.regency.name : '');
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{kecamatan}}/g, (response.data.member.district) ? response.data.member.district.name: '');
                        });
                        $("#print-area").html(function(index,html){
                            return html.replace(/{{desa}}/g, (response.data.member.village) ? response.data.member.village.name : '');
                        });
                        $("#print-area :not(:has(*))").html((index,self) => {
                            return self.replace(/{{created_at}}/g, this.$filter_date(response.data.member.created_at));
                        });

                        var pasfoto = (response.data.member.pasfoto) ? process.env.VUE_APP_ASSETS_FOLDER + '/member_pasfoto/' + response.data.member.pasfoto : process.env.VUE_APP_DEFAULT_AVATAR;
                        $("#print-area #card_pasfoto").attr('src', pasfoto);

                        $("#print-area :not(:has(*))").html((index,html) => {
                            return html.replace(/<style>/g, '<style media="print">');
                        });
                        $("#print-area :not(:has(*))").html((index,html) => {
                            return html.replace(/<!-- pagebreak -->/g, '<div class="page-break"></div>');
                        });

                    }, 10);
                    setTimeout(() => {
                        const cssText = `
                        * {
                            box-sizing: border-box;
                        }
                        .hide {
                            display: block;
                        }
                        .page-break {
                            page-break-after: always;
                        }
                        `;
                        const d = new this.$Printd();
                        d.print( document.getElementById('print-area'), [ cssText ] );
                    }, 500)
                } else if (response.data.status == '0'){
                    this.kta_status = response.data.status;
                    this.kta_error_message = response.data.message;
                }
            })
            .catch((error) => {
                this.$Progress.fail();
                this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
            });
        },
	},
	beforeCreate(){},
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadData();
	},
}
</script>

<style>
.hide{
    display: none;
}
</style>